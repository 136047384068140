<template>
<div class="wrapper">
  <div class="cart">
    <div class="cartItem" v-for="(item, index) in cart" :key="item.id">
      <div class="image">
        <img :src="'/images/products/'+item.image">
      </div>
      <div class="info">
        <p>{{item.country}}</p>
        <h1>{{item.name}}</h1>

        <div class="price">
          <h2>{{item.price}}</h2>
          <button @click="removeItem(index)" class="auto">Remove</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'CartList',
  props: {
    cart: Array
  },
  methods: {
    removeItem(index) {
      this.$root.$data.cart.splice(index, 1);
    }
  }
}
</script>

<style>
.cartItem {
  display: flex;
  margin: 50px 0;
}

.info {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info h1 {
  margin: 0px;
}

.info p {
  margin: 0px;
}

button {
  background-color: #b84901;
  color: white;
  border: none;
  padding: 10px;
}

</style>
