<template>
<div>
  <h1>Cart</h1>
  <div v-if="cart.length > 0">
    <CartList :cart="cart"/>
  </div>
  <div v-else>
    <p>cart is empty</p>
  </div>
</div>
</template>

<script>
import CartList from "../components/CartList.vue"
export default {
  name: 'Cart',
  components: {
    CartList
  },
  data() {
    return {
      cart: this.$root.$data.cart,
    }
  }
}
</script>
